import React, {useEffect, useState} from "react";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Axios from 'axios';

function Slitter(props) {
    const [height, setHeight] = useState(0);
    const [dim, setDim] = useState(0);
    const [foam, setFoam] = useState(null);
    const [type, setType] = useState(null);
    const [qty, setQty] = useState(0);
    const [expectedQty, setExpectedQty] = useState(1000000);
    const [loading, setLoading] = useState(false);
    const [number, setNumber] = useState(0);
    const [trueNumber, setTrueNumber] = useState(0);

    const lang = {
        "en": {
            "width":"Width",
            "length":"Length",
            "height":"Height",
            "continue":"Continue",
            "back":"Back",
            "foam":"Foam Type",
            "ortho":"Orthopedic",
            "memory":"Memory Foam",
            "gel":"Cooling Gel",
            "cup":"Cup",
            "donut":"Donut",
            "slab":"Slab",
            "oval":"Oval",
            "shape":"Foam Shape",
            "size":"Foam Size",
            "xs":"Extra Small",
            "sm":"Small",
            "md":"Medium",
            "lg":"Large",
            "jm":"Jumbo",
            "jp":"Jumbo Plus",
            "gl":"Goliath",
            "qty":"Cut Quantity",
            "expected":"Expected Quantity:",
            "submit":"Submit",
            "number":"Number of Blocks"
        },
        "es": {
            "width":"Ancho",
            "length":"Largo",
            "height":"Altura",
            "continue":"Continuar",
            "back":"Regresa",
            "foam":"Tipo de Espuma",
            "ortho":"Ortopédico",
            "memory":"Espuma de Memoria",
            "gel": "Gel Refrescante",
            "cup":"Taza",
            "donut":"Rosquilla",
            "slab":"Losa",
            "oval":"Oval",
            "shape":"Forma de Espuma",
            "size":"Tamaño de la Espuma",
            "xs":"Extra pequeño",
            "sm":"Pequeño",
            "md":"Medio",
            "lg":"Grande",
            "jm":"Jumbo",
            "jp":"Jumbo Plus",
            "gl":"Goliat",
            "qty":"Cantidad",
            "expected":"Cantidad de Corte",
            "submit":"Enviar",
            "number":"Numero de Bloques"
        }
    }

    function saveDims() {
        let trueHeight = parseFloat(height);
        setDim(trueHeight);
    }

    function goBack() {
        if(type) {
            setType(null);
        }
        else if(trueNumber) {
            setTrueNumber(0);
        }
        else {
            setDim(0);
        }
    }

    function changeFoam(val) {
        if(val === "default") {
            setFoam(null);
        }
        else {
            setFoam(val);
        }
    }

    function saveFoam() {
        if(foam) {
            setType(foam);
        }
    }

    function reset() {
        setHeight(0)
        setDim(0)
        setFoam(null)
        setType(null)
        setQty(0)
        setTrueNumber(0);
        setExpectedQty(1000000)
        document.querySelectorAll("input").forEach((node) => {node.value = ""})
        setLoading(false);
    }

    function submit() {
        setLoading(true);
        submitRequest().then(function() {
            reset();
        }).catch(function(err){
            console.log(err);
            reset();
        })
    }

    function saveNumber() {
        if(number) {
            setTrueNumber(number);
        }
    }

    function submitRequest() {
        return new Promise((resolve, reject) => {
            let url = props.base + "/operations/saveFoam";
            Axios.put(url, {"length":1,"width":1,"height":height,"actual":qty,"expected":expectedQty,"foam":type,"shape":"Slitter", "size":"Uniform"}, {mode: 'no-cors'}).then((result) => {resolve(result.data.orders)}).catch((error) => {console.log(error); reject()});
        })
    }

    return (
        <Box display="flex" flexDirection="column">
                {dim ? <Box style={{margin:"auto", marginTop:"10px"}}><Button onClick={()=>goBack()} variant="contained" color="error">{lang[props.locale].back}</Button></Box> : null}
                <Box display="flex" style={{margin: "auto", marginTop:"45px"}}>
                    <TextField disabled={dim ? true : false} InputProps={{ inputProps: { min: 0} }} label={lang[props.locale].height} variant="outlined" id="height" onChange={(e)=>setHeight(e.target.value)} type="number"></TextField>
                </Box>
                {!dim ? <Box display="flex">
                    <Button disabled={!height} onClick={()=>saveDims()} style={{margin:"auto", marginTop:"20px"}} variant="contained" color="success">{lang[props.locale].continue}</Button>
                </Box> : null}
                {dim ? <Box display="flex" style={{margin: "auto", marginTop:"45px"}}>
                    <TextField disabled={trueNumber ? true : false} InputProps={{ inputProps: { min: 0} }} label={lang[props.locale].number} variant="outlined" id="height" onChange={(e)=>setNumber(e.target.value)} type="number"></TextField>
                </Box> : null}
                {!trueNumber && dim? <Box display="flex">
                    <Button disabled={!number} onClick={()=>saveNumber()} style={{margin:"auto", marginTop:"20px"}} variant="contained" color="success">{lang[props.locale].continue}</Button>
                </Box> : null}
                {dim && trueNumber ? <Box display="flex">
                    <Select onChange={(e)=>changeFoam(e.target.value)} id="foam" defaultValue="default" style={{margin:"auto",marginTop:"20px", width:"fit-content"}} disabled={type ? true : false} label={lang[props.locale].foam} variant="outlined">
                        <MenuItem value="default">{lang[props.locale].foam}</MenuItem>
                        <MenuItem value="Ortho">{lang[props.locale].ortho}</MenuItem>
                        <MenuItem value="Memory">{lang[props.locale].memory}</MenuItem>
                        <MenuItem value="Gel">{lang[props.locale].gel}</MenuItem>
                    </Select>
                </Box> : null}
                {!type && dim && trueNumber ? <Box display="flex">
                        <Button disabled={!foam} onClick={()=>saveFoam()} style={{margin:"auto", marginTop:"20px"}} variant="contained" color="success">{lang[props.locale].continue}</Button>
                    </Box> : null}
                {dim && type && trueNumber ? <Box display="flex">
                <Box display="flex" style={{margin:"auto"}} flexDirection="column"><h2>{lang[props.locale].expected + " " + String(expectedQty)}</h2>
                    <TextField InputProps={{ inputProps: { min: 0} }} label={lang[props.locale].qty} variant="outlined" id="width" onChange={(e)=>setQty(e.target.value)} type="number"></TextField></Box>
                </Box> : null}
                {type && dim && trueNumber ? <Box display="flex">
                        <Button disabled={!qty || loading} onClick={()=>submit()} style={{margin:"auto", marginTop:"20px"}} variant="contained" color="success">{lang[props.locale].submit}{loading ? <CircularProgress size={24} style={{color:'green', position:'absolute'}}></CircularProgress> : null }</Button>
                    </Box> : null}
        </Box>
    );

}

export default Slitter;
