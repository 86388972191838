import React, {useState, useEffect} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Popup from 'reactjs-popup';
import CircularProgress from '@mui/material/CircularProgress';
import Axios from 'axios';
import Moment from "moment-timezone";

export default function HistoryTable(props) {
    const [history, updateHistory] = useState([]);
    const [error, changeErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        pullHistory()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.refresh]);

    const lang = {
        "en": {
            "order":"Order Number",
            "customer":"Customer",
            "qty":"Quantity",
            "err":"Failed to refresh history.",
            "end":"End Day",
            "endWarning":"Warning: Tapping 'Accept' will log workers off of the line, clear the build history, and submit the end of day report.",
            "cancel":"Cancel",
            "accept":"Accept",
            "endErr": "Failed to end day.",
            "refresh": "Refresh"
        },
        "es": {
            "order":"Número de Orden",
            "customer":"Vendedor",
            "qty":"Cantidad",
            "err":"No se pudo actualizar el historial.",
            "end":"Terminar el Dia",
            "endWarning":"Advertencia: al tocar 'Aceptar', los trabajadores se desconectarán de la línea, se borrará el historial de compilación y se enviará el informe de fin del día.",
            "cancel":"Cancelar",
            "accept":"Aceptar",
            "endErr": "No se pudo finalizar el día.",
            "refresh": "refrescar"
        }
    }

    function openPopup() {
        document.getElementById("root").style.filter = 'blur(4px)';
        setOpen(true);
    }

    function closePopup() {
        setOpen(false);
        document.getElementById("root").style.filter = "";
    }

    function pullHistory() {
        updateHistory([]);
        changeErr(false);
        setLoading(true);
        historyRequest().then(function(result) {
            updateHistory(result);
            setLoading(false);
        }).catch(function(err) {console.log(err); changeErr(true); setLoading(false);})
    }

    function historyRequest() {
        return new Promise((resolve, reject) => {
            let url = props.base + "/operations/getHistory?wh=" + props.wh + "&line=" + props.group;
            Axios.get(url, {mode: 'no-cors'}).then((result) => {resolve(result.data)}).catch((error) => {console.log(error); reject(error)});
        })
    }

    function endDayRequest() {
        return new Promise((resolve, reject) => {
            let url = props.base + "/operations/endDay";
            Axios.put(url, {"wh":props.wh, "line":props.group, "date":Moment(Date.now()).tz("America/New_York").format('YYYY-MM-DD HH:mm:ss')}, {mode: 'no-cors'}).then((result) => {resolve(result.data)}).catch((error) => {console.log(error); reject(error)});
        })
    }


    function endDayAlert() {
        setLoading(true);
        endDayRequest().then(function() {
            window.location.reload();
        })
        .catch(function(err) {
            console.log(err);
            // window.location.reload();
            changeErr(lang[props.locale].endErr);
            setLoading(false);
            closePopup();
        })
    }


    return (
        <Box display="flex" flexDirection="column">
            <Popup style={{margin:"auto"}} open={open} onClose={() => closePopup()}>
                <Box display="flex" flexDirection="column" style={{ padding: "5px", border:"1px solid black", background:"white", width: "50%", margin:"auto"}}>
                    {lang[props.locale].endWarning}
                    <Box display="flex" style={{margin:"auto", width:"70%", marginTop:"15px"}}>
                        <Button style={{borderTopLeftRadius: "0", borderBottomLeftRadius: "0", marginRight:"auto"}} variant="contained" color="warning" disabled={loading} onClick={() => closePopup()}>{lang[props.locale].cancel}</Button>
                        <Button style={{borderTopLeftRadius: "0", borderBottomLeftRadius: "0", marginLeft:"auto"}} variant="contained" color="primary" disabled={loading} onClick={() => endDayAlert()}>{lang[props.locale].accept}{loading ? <CircularProgress size={30} style={{color:'black', position:'absolute'}}></CircularProgress> : null }</Button>
                    </Box>
                </Box>
            </Popup>
            {!loading ? <Button style={{width:"fit-content", marginLeft:"auto", marginBottom:"20px"}} color="success" variant="outlined" onClick={()=>pullHistory()}>{lang[props.locale].refresh}</Button> : null}
            {history.length > 0 ? <Button variant="contained" color="secondary" onClick={() => openPopup()} style={{margin:"auto", marginBottom:"15px"}}>{lang[props.locale].end}</Button> : null}
            <TableContainer component={Paper}>
                {loading ? <LinearProgress color="success" /> : null }
                {error ? <Alert severity="error">{lang[props.locale].err}</Alert> : null}
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {lang[props.locale].order}
                            </TableCell>
                            <TableCell>
                                {lang[props.locale].customer}
                            </TableCell>
                            <TableCell>
                                {lang[props.locale].qty}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {history.map((row, index) => (
                            <TableRow key={"row" + index}>
                                <TableCell component="th" scope="row">
                                    {row.order_number}
                                </TableCell>
                                <TableCell>
                                    {row.customer}
                                </TableCell>
                                <TableCell>
                                    {row.qty}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
        
    )
}