import React, {useEffect, useState} from "react";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Axios from 'axios';

function CNC(props) {
    const [width, setWidth] = useState(0);
    const [length, setLength] = useState(0);
    const [height, setHeight] = useState(0);
    const [dim, setDim] = useState(0);
    const [foam, setFoam] = useState(null);
    const [type, setType] = useState(null);
    const [shape, setShape] = useState(null);
    const [tempShape, setTempShape] = useState(null);
    const [size, setSize] = useState(null);
    const [tempSize, setTempSize] = useState(null);
    const [qty, setQty] = useState(0);
    const [expectedQty, setExpectedQty] = useState(0);
    const [loading, setLoading] = useState(false);

    const lang = {
        "en": {
            "width":"Width",
            "length":"Length",
            "height":"Height",
            "continue":"Continue",
            "back":"Back",
            "foam":"Foam Type",
            "ortho":"Orthopedic",
            "memory":"Memory Foam",
            "gel":"Cooling Gel",
            "cup":"Cup",
            "donut":"Donut",
            "slab":"Slab",
            "oval":"Oval",
            "shape":"Foam Shape",
            "size":"Foam Size",
            "xs":"Extra Small",
            "sm":"Small",
            "md":"Medium",
            "lg":"Large",
            "jm":"Jumbo",
            "jp":"Jumbo Plus",
            "gl":"Goliath",
            "qty":"Cut Quantity",
            "expected":"Expected Quantity:",
            "submit":"Submit"
        },
        "es": {
            "width":"Ancho",
            "length":"Largo",
            "height":"Altura",
            "continue":"Continuar",
            "back":"Regresa",
            "foam":"Tipo de Espuma",
            "ortho":"Ortopédico",
            "memory":"Espuma de Memoria",
            "gel": "Gel Refrescante",
            "cup":"Taza",
            "donut":"Rosquilla",
            "slab":"Losa",
            "oval":"Oval",
            "shape":"Forma de Espuma",
            "size":"Tamaño de la Espuma",
            "xs":"Extra pequeño",
            "sm":"Pequeño",
            "md":"Medio",
            "lg":"Grande",
            "jm":"Jumbo",
            "jp":"Jumbo Plus",
            "gl":"Goliat",
            "qty":"Cantidad",
            "expected":"Cantidad de Corte",
            "submit":"Enviar"
        }
    }

    const size_dims = {
        "ortho": { 
            "slab": {
                "small" : 25.5 * 34.5 * 2,
                "medium": 33.5 * 42.5 * 2,
                "large": 24 * 35 * 5.5,
                "jumbo": 30 * 45 * 6,
                "jumboPlus": 36 * 51 * 8,
                "goliath": 42 * 58 * 9
            }
        }
    }

    function saveDims() {
        let trueWidth = parseFloat(width);
        let trueLength = parseFloat(length);
        let trueHeight = parseFloat(height);
        setDim(trueWidth * trueLength * trueHeight);
    }

    function goBack() {
        if(size) {
            setSize(null);
        }
        else if(shape && props.line !== 14 && props.line !== 6) {
            setShape(null);
        }
        else if(type) {
            setType(null);
        }
        else {
            setDim(0);
        }
    }

    function changeFoam(val) {
        if(val === "default") {
            setFoam(null);
        }
        else {
            setFoam(val);
        }
    }

    function saveFoam() {
        if(foam) {
            setType(foam);
        }
        if(props.line === 6 || props.line === 14) {
            setShape("Slab");
            // document.querySelector("#shape select").value = "Slab";
        }
    }

    function changeShape(val) {
        if(val === "default") {
            setTempShape(null);
        }
        else {
            setTempShape(val);
        }
    }

    function saveShape() {
        if(tempShape) {
            setShape(tempShape);
        }
    }

    function changeSize(val) {
        if(val === "default") {
            setTempSize(null);
        }
        else {
            setTempSize(val);
        }
    }

    function saveSize() {
        if(tempSize) {
            setSize(tempSize);
            let expDims = size_dims[type][shape][tempSize]
            setExpectedQty(Math.floor(dim / expDims));
        }
    }
    
    function reset() {
        setWidth(0)
        setLength(0)
        setHeight(0)
        setDim(0)
        setFoam(null)
        setType(null)
        setShape(null)
        setTempShape(null)
        setSize(null)
        setTempSize(null)
        setQty(0)
        setExpectedQty(0)
        document.querySelectorAll("input").forEach((node) => {node.value = ""})
        setLoading(false);
    }

    function submit() {
        setLoading(true);
        submitRequest().then(function() {
            reset();
        }).catch(function(err){
            console.log(err);
            reset();
        })
    }

    function submitRequest() {
        return new Promise((resolve, reject) => {
            let url = props.base + "/operations/saveFoam";
            Axios.put(url, {"length":length,"width":width,"height":height,"actual":qty,"expected":expectedQty,"foam":type,"shape":shape, "size":size}, {mode: 'no-cors'}).then((result) => {resolve(result.data.orders)}).catch((error) => {console.log(error); reject()});
        })
    }

    return (
        <Box display="flex" flexDirection="column">
                {dim ? <Box style={{margin:"auto", marginTop:"10px"}}><Button onClick={()=>goBack()} variant="contained" color="error">{lang[props.locale].back}</Button></Box> : null}
                <Box display="flex" style={{margin: "auto", marginTop:"45px"}}>
                    <TextField disabled={dim ? true : false} InputProps={{ inputProps: { min: 0} }} label={lang[props.locale].width} variant="outlined" id="width" onChange={(e)=>setWidth(e.target.value)} type="number"></TextField>
                    <CloseIcon fontSize="large" style={{margin:"auto"}} />
                    <TextField disabled={dim ? true : false} InputProps={{ inputProps: { min: 0} }} label={lang[props.locale].length} variant="outlined" id="length" onChange={(e)=>setLength(e.target.value)} type="number"></TextField>
                    <CloseIcon fontSize="large" style={{margin:"auto"}} />
                    <TextField disabled={dim ? true : false} InputProps={{ inputProps: { min: 0} }} label={lang[props.locale].height} variant="outlined" id="height" onChange={(e)=>setHeight(e.target.value)} type="number"></TextField>
                </Box>
                {!dim ? <Box display="flex">
                    <Button disabled={!width || !length || !height} onClick={()=>saveDims()} style={{margin:"auto", marginTop:"20px"}} variant="contained" color="success">{lang[props.locale].continue}</Button>
                </Box> : null}
                {dim ? <Box display="flex">
                    <Select onChange={(e)=>changeFoam(e.target.value)} id="foam" defaultValue="default" style={{margin:"auto",marginTop:"20px", width:"fit-content"}} disabled={type ? true : false} label={lang[props.locale].foam} variant="outlined">
                        <MenuItem value="default">{lang[props.locale].foam}</MenuItem>
                        <MenuItem value="ortho">{lang[props.locale].ortho}</MenuItem>
                        <MenuItem value="memory">{lang[props.locale].memory}</MenuItem>
                        <MenuItem value="gel">{lang[props.locale].gel}</MenuItem>
                    </Select>
                </Box> : null}
                {!type && dim ? <Box display="flex">
                        <Button disabled={!foam} onClick={()=>saveFoam()} style={{margin:"auto", marginTop:"20px"}} variant="contained" color="success">{lang[props.locale].continue}</Button>
                    </Box> : null}
                {dim && type && props.line !== 14 && props.line !== 6 ? <Box display="flex">
                    <Select onChange={(e)=>changeShape(e.target.value)} id="shape" defaultValue="default"  style={{margin:"auto",marginTop:"20px", width:"fit-content"}} disabled={shape ? true : false} label={lang[props.locale].shape} variant="outlined">
                        <MenuItem value="default">{lang[props.locale].shape}</MenuItem>
                        <MenuItem value="oval">{lang[props.locale].oval}</MenuItem>
                        <MenuItem value="cup">{lang[props.locale].cup}</MenuItem>
                        <MenuItem value="slab">{lang[props.locale].slab}</MenuItem>
                    </Select>
                </Box> : null}
                {!shape && type && dim && props.line !== 14 && props.line !== 6 ? <Box display="flex">
                        <Button disabled={!tempShape} onClick={()=>saveShape()} style={{margin:"auto", marginTop:"20px"}} variant="contained" color="success">{lang[props.locale].continue}</Button>
                    </Box> : null}
                {shape && dim && type ? <Box display="flex">
                    <Select onChange={(e)=>changeSize(e.target.value)} id="size" defaultValue="default"  style={{margin:"auto",marginTop:"20px", width:"fit-content"}} disabled={size ? true : false} label={lang[props.locale].size} variant="outlined">
                        <MenuItem value="default">{lang[props.locale].size}</MenuItem>
                        <MenuItem value="extraSmall">{lang[props.locale].xs}</MenuItem>
                        <MenuItem value="small">{lang[props.locale].sm}</MenuItem>
                        <MenuItem value="medium">{lang[props.locale].md}</MenuItem>
                        <MenuItem value="large">{lang[props.locale].lg}</MenuItem>
                        <MenuItem value="jumbo">{lang[props.locale].jm}</MenuItem>
                        <MenuItem value="jumboPlus">{lang[props.locale].jp}</MenuItem>
                        <MenuItem value="goliath">{lang[props.locale].gl}</MenuItem>
                    </Select>
                </Box> : null}
                {!size && shape && type && dim ? <Box display="flex">
                        <Button disabled={!tempSize} onClick={()=>saveSize()} style={{margin:"auto", marginTop:"20px"}} variant="contained" color="success">{lang[props.locale].continue}</Button>
                    </Box> : null}
                {size && shape && dim && type ? <Box display="flex">
                <Box display="flex" style={{margin:"auto"}} flexDirection="column"><h2>{lang[props.locale].expected + " " + String(expectedQty)}</h2>
                    <TextField InputProps={{ inputProps: { min: 0} }} label={lang[props.locale].qty} variant="outlined" id="width" onChange={(e)=>setQty(e.target.value)} type="number"></TextField></Box>
                </Box> : null}
                {size && shape && type && dim ? <Box display="flex">
                        <Button disabled={!tempSize || loading} onClick={()=>submit()} style={{margin:"auto", marginTop:"20px"}} variant="contained" color="success">{lang[props.locale].submit}{loading ? <CircularProgress size={24} style={{color:'green', position:'absolute'}}></CircularProgress> : null }</Button>
                    </Box> : null}
        </Box>
    );

}

export default CNC;
