import React, {useState} from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LineButton from "./components/line_button";
import line_data from "./data/lines.json"
import warehouses from "./data/warehouses.json"
import WarehouseButton from "./components/warehouse_button"
import ProductionTablet from "./components/production_tablet"


function App() {
  const [warehouse, changeWarehouse] = useState(null);
  const [group, changeGroup] = useState(null);

  function createButton(line, index) {
    return <LineButton wh={warehouse.name} key={index} line={line} func={lineOnClick} name={line.name} color={line.color}/>
  }

  function lineOnClick(line) {
    console.log(line);
    changeGroup(line);
  } 

  function createButtons(lines) {
    return lines.map(createButton);
  }

  function whOnClick(warehouse) {
    changeWarehouse(warehouse);
  }

  function createWarehouse(warehouse, index) {
    return <WarehouseButton key={index} wh={warehouse} name={warehouse.name} func={whOnClick} color={warehouse.color}/>
  }

  function createWarehouses(warehouses) {
    return warehouses.map(createWarehouse);
  }

  function createProductionTablet() {
    return <ProductionTablet resetter={resetWh} wh={warehouse} group={group} />
  }

  function resetWh() {
    changeGroup(null);
    changeWarehouse(null);
  }

  function refreshPage() {
    window.location.reload();
  }

    return(
      <Box display="flex" flexDirection="column">
        {group== null ? (warehouse === null ? createWarehouses(warehouses.warehouses) : createButtons(line_data[warehouse.id].lines)) : createProductionTablet()}
        {warehouse === null ? <Button style={{width:"10%", margin:"auto", marginTop:"80px", marginRight:"80px"}} variant="contained" color="secondary" onClick={()=>refreshPage()}>Refresh</Button> : null}
      </Box>
    )

}



export default App;
