import React from 'react';
import Button from '@mui/material/Button';



let LineButton = function statelessFunctionComponentClass(props) {
    let line = props.line;
    let style = {
        width: "80%",
        height: "fit-content",
        border: "1px solid " + props.color,
        color: props.color,
        fontSize: "25px",
        margin: "auto",
        marginTop: "25px",
        background: "white"
    };
    let name = props.name;
    
    return (
        <Button id={"line" + line} type="button" style={style} onClick={() => props.func(line)}>{props.wh + " " + name}</Button>
    );

}

export default LineButton;