import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export default function NavBar(props) {
    
    function setEnglish() {
        props.setter("en");
    }

    function setSpanish() {
        props.setter("es");
    }

    return(<AppBar position="static">
            <Toolbar style={{background:props.color}}>
                <Box display="flex" style={{width:"100%"}}>
                    <Button onClick={() => props.resetter()}><KeyboardBackspaceIcon style={{color:"white", fontSize:"24px"}}/></Button>
                    <Typography variant="h6">{props.name}</Typography>
                    {props.locale === "en" ? <Button style={{marginLeft:"auto"}} onClick={() => setEnglish()} color="inherit">Espaniol</Button> : <Button style={{marginLeft:"auto"}} onClick={() => setSpanish()} color="inherit">English</Button>}
                </Box>
            </Toolbar>
        </AppBar>)
}