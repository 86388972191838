import React, {useState} from 'react';
import Box from '@mui/material/Box';
import NavBar from './nav_bar';
import ControlledAccordions from './top_accordion';
import OrderSearch from './order_search';
import OrderTable from './order_table';
import CNC from './foamCutter';
import Slitter from './foamSlitter';

function ProductionTablet(props) {
    const [order, changeOrder] = useState(null);
    const [type, changeType] = useState("");
    const [locale, changeLocale] = useState("en");
    const [refresh, updateRefresh] = useState(0);
    const [employees, updateEmployees] = useState([]);

    const os = require('os');
    let api = "furhaven-production.com/v1";
    if(os.hostname().indexOf("local") > -1 || os.hostname().indexOf("dev.") > -1) {
        api = "dev." + api;
    }
    const baseApiUrl = "https://api." + api;

    function goBack() {
        changeOrder(null);
        changeType("");
        props.resetter();
    }

    function refreshToken() {
        updateRefresh(refresh + 1);
    }


    function setOrder(res) {
        let out = {
            "Customer": "",
            "CustomerOrderNbr": "",
            "OrderNbr": "",
            "Date":"",
            "Items": [],
            "Total": 0,
            "Type": ""
        };
        for(let i = 0; i < res.length; i++) {
            out["Customer"] += res[i].Customer;
            out["CustomerOrderNbr"] += res[i].CustomerOrderNbr;
            out["OrderNbr"] += res[i].OrderNbr;
            out["Date"] = res[i].Date;
            out["Type"] = res[i].Type;
            if(i < res.length - 1) {
                out["Customer"] += ", ";
                out["CustomerOrderNbr"] += ", ";
                out["OrderNbr"] += ", ";
            }
            for(let j = 0; j < res[i].Items.length; j++) {
                out["Items"].push(res[i].Items[j]);
                out["Total"] += res[i].Items[j].Qty;
            }
        }
        changeOrder(out);
    }

    function setType(res) {
        changeType(res);
    }

    function resetOrder() {
        changeOrder(null);
    }

    function updateLocale() {
        if(locale === "en") {
            changeLocale("es");
        }
        else {
            changeLocale("en");
        }
    }

    return (
        <div>
            <NavBar resetter={goBack} name={props.wh.name + " " + props.group.name} color={props.group.color} locale={locale} setter={updateLocale} />
            {(props.wh.id === "KY" && props.group.line <= 13) || (props.wh.id === "PA" && props.group.line <= 6) ? 
            <Box style={{marginTop: "10px"}} display="flex" flexDirection="column">
                <div style={{margin: "auto"}}>
                    <ControlledAccordions base={baseApiUrl} employees={employees} update={updateEmployees} refresh={refresh} wh={props.wh.id} locale={locale} group={props.group.line} />
                </div>
                <div style={{margin: "auto", marginTop: "30px", width: "80%"}}>
                    {!order ? <OrderSearch base={baseApiUrl} wh={props.wh} group={props.group} locale={locale} typeSetter={setType} setter={setOrder} color={props.group.color} /> : <OrderTable base={baseApiUrl} wh={props.wh.id} branch={props.wh.branch} group={props.group.line} refresh={refreshToken} locale={locale} employees={employees} totalQty={order.Total} type={type} orderNbr={order.OrderNbr} shipmentNbr={order.ShipmentNbr} orderDate={order.Date} location={order.Location} customer={order.Customer} order={order.Items} resetter={resetOrder} />}
                </div>
            </Box>
            : 
            <Box display="flex" flexDirection="column" style={{marginTop:"10px"}}>
                {(props.wh.id === "KY" && props.group.line >= 14) || (props.wh.id === "PA" && props.group.line >= 7) ?
                    <CNC base={baseApiUrl} locale={locale} line={props.group.line}/> :
                    <Slitter base={baseApiUrl} locale={locale} line={props.group.line} /> }
            </Box>
            }
        </div>
    );

}

export default ProductionTablet;